import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../layouts/DefaultLayout";
import Image from "../components/Image";

const TimberSpeciesColumn: React.FC = ({ children }) => (
  <div className="col-sm-6 col-lg-4 pb-3">{children}</div>
);

interface TimberSpeciesCardProps {
  title: string;
  strength: string;
  weathering: string;
  finish: string;
}

const TimberSpeciesCard: React.FC<TimberSpeciesCardProps> = ({
  title,
  strength,
  weathering,
  finish,
  children
}) => {
  const name = title.toLowerCase().replace(/ /g, "_");

  return (
    <div className="card h-100">
      <div className="card-image">
        <Image name={name} />
      </div>
      <div className="card-body">
        <h3 className="card-title">{title}</h3>
        <div className="text-size-small">
          <p className="card-text">
            <strong>Strength:&nbsp;</strong>
            {strength}
          </p>
          <p className="card-text">
            <strong>Weathering:&nbsp;</strong>
            {weathering}
          </p>
          <p className="card-text">
            <strong>Finish:&nbsp;</strong>
            {finish}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Why Timber?">
    <div className="container my-5">
      <p className="text-center text-size-medium">
        Timber will never go out of style.
      </p>
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <p>
            The colour and texture variations are what make timber stand apart.
            It is not laminate and has its own character.
          </p>
          <p>
            The quality control and standards maintained by our company ensure
            that only the best timber is used.
          </p>
          <p>
            Doors are designed to allow for seasonal movement and the ageless
            charm of timber will add value and enjoyment to your home.
          </p>
          <p>
            Timbers and veneers, even with today’s technology and space age
            materials, are still regarded as the most prestigious material for
            all furniture. No man-made process can duplicate the natural
            variations in colour and texture that makes timbers stand apart.
          </p>
        </div>
      </div>
    </div>
    <div className="bg-white py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-10 col-sm-8 col-md-4 col-lg-3 d-flex flex-column justify-content-center">
            <Image name="timber1" className="round mb-5" />
          </div>
          <div className="col-md-8 col-lg-9">
            <h2 className="mb-3 text-center text-md-left">
              Is timber right for you?
            </h2>
            <p>
              <strong>Timber varies greatly in colour.</strong> It can have
              tones of red, black, brown, gray, white, blue or green even within
              one length of timber. If you wish to achieve uniformity of colour
              then timber may not be suitable.
            </p>
            <p>
              <strong>Timber is not fault-free.</strong> It can have knot holes,
              burls, gum vein and the grain can vary considerably.
            </p>
            <p>
              <strong>Timber is affected by the climate.</strong> It expands in
              moist environments and contracts in dry conditions.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container my-5">
      <h2 className="text-center">Timber Species</h2>
    </div>

    <div className="container my-5">
      <div className="row justify-content-center">
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="American Oak"
            strength="A strong and durable hard wood. Very resistant to insect 
              and fungal attack due to its high tannin content."
            weathering="High level of water resistance but not to the same 
              degree as Jarrah, which is a denser timber."
            finish="May be stained or polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Blackwood"
            strength="A hardwood which seasons to a very dense product with 
              little movement."
            weathering="Fairly water resistant but will deteriorate if left 
              outside for long periods."
            finish="May be stained or polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Jarrah"
            strength="Hardwood. Becomes very hard and dense when seasoned, 
              making it a heavier timber. Termite resistant."
            weathering="Very durable, even in wet and weathered conditions, 
              making it a choice structural material. Remarkably 
              resistant to rot. May fade if constantly exposed to sunlight."
            finish="Cannot be stained. May be polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Tasmanian Oak"
            strength="A hardwood which is very durable and strong."
            weathering="Not particularly water resistant so should not be used 
              for products exposed to the elements."
            finish="May be stained or polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Western Red Cedar"
            strength="A soft wood which is extremely light."
            weathering="It has amazing weathering properties being able to 
              withstand sun, rain and most insect or fungal diseases. Often used
              as cladding for buildings."
            finish="May be stained or polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Baltic Pine"
            strength="A soft wood so it does not have particularly good
              strength."
            weathering="Not very resistant to the elements being a soft wood 
              and should therefore not be used outdoors."
            finish="May be stained or polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Clear Pine"
            strength="A soft wood so it does not have particularly good
              strength."
            weathering="Not very resistant to the elements being a soft wood 
              and should therefore not be used outdoors."
            finish="May be stained or polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Rosewood"
            strength="Classified as a hardwood but it does not have the same 
              density as other hardwoods."
            weathering="Suitable indoors or outdoors as it is durable in all 
              conditions and very stable."
            finish="Looks best polished."
          />
        </TimberSpeciesColumn>
        <TimberSpeciesColumn>
          <TimberSpeciesCard
            title="Spotted Gum"
            strength="Spotted Gum is an amazingly dense timber which means it 
              is incredible heavy and strong."
            weathering="Can be used indoors and outdoors, it has brilliant 
              resistance to the elements."
            finish="Looks best polished."
          />
        </TimberSpeciesColumn>
      </div>
    </div>
    <div className="container my-5">
      <h2 className="text-center">Timber Finishes</h2>
    </div>

    <div className="container my-5">
      <p className="text-center text-size-medium mb-4">
        There are many ways to finish your timber product. These are the most
        popular:
      </p>
      <div className="card my-3">
        <div className="card-body">
          <h3 className="card-title">Polishing</h3>
          <p className="card-text">
            Spraying the timber with a polyurethane varnish which is transparent
            in colour. This is non toxic and gives a tough finish after it is
            cured. Polishing makes the timber water resistant and brings out a
            darker/richer colour in the timber. One application needed.
          </p>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <h3 className="card-title">Oiling</h3>
          <p className="card-text">
            Oiling with products such as Linseed oil or Tung oil will make the
            product water resistant, however it will need frequent applications
            and over time all oiled timbers will become darker and lose their
            original colour. Oiling is not recommended for bench tops as
            generally only one side is accessible and adding moisture to one
            only one side can cause the timber to cup or bow.
          </p>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <h3 className="card-title">Staining</h3>
          <p className="card-text">
            Many timbers can be stained using a wood stain, this will change the
            colour of the timber but leave the grain patterns unaffected. The
            success of staining will depend on the porosity of the timber; pine
            is mainly used for staining as it is a soft wood with a light
            colour. Only one application will be needed if it is followed by
            polishing.
          </p>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <h3 className="card-title">Lime Wash</h3>
          <p className="card-text">
            Lime wash is a slake lime in water, slaked lime is substance used in
            white wash which produces a similar effect effect. Lime wash is
            painted on and cured through a reaction with the carbon dioxide in
            the air to produce calcium carbonate. Lime wash will give the timber
            a white colour whilst retaining the grain of the timber.
          </p>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <h3 className="card-title">Painting</h3>
          <p className="card-text">
            Timber can be painted to any number of colours or finishes; however
            painting timber can cause a number of issues. Firstly timber expands
            and contracts during the seasons and a layer of paint will crack and
            split along joins, particularly between the panel and frame on
            timber doors. Secondly painting timber disguises all natural
            elements of timber such as the colour and grain pattern. It is
            recommended for painted items to use a man made product such as MDF
            which will give a smooth base with no movement as well as being a
            more cost effective product.
          </p>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
